<style lang="less" scoped>
  @import "../../assets/css/variables";
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>系统日志</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container clearfix" v-on:keyup.13="normalSearch">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-input
            clearable
            style="width: 200px"
            v-model="filters.name"
            placeholder="请输入操作人"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-select
            clearable
            v-model="filters.module"
            style="width: 200px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label>
          <el-date-picker
            style="width: 360px"
            v-model="fragment"
            type="datetimerange"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="timestamp"
            :picker-options="pickerOptions"
            size="medium"
          ></el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="normalSearch">查询</el-button>
        </el-form-item>
      </el-form>

      <el-table
        :data="systemlogList"
        v-loading.body="listLoading"
        @selection-change="selsChange"
        class="list-table"
      >
        <el-table-column type="selection" width="35"></el-table-column>
        <el-table-column label="序号" align="center" width="70">
          <template slot-scope="scope">
            <span>{{ scope.$index + pager.size * (pager.page - 1) + 1 }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="content"
          min-width="100"
          :show-overflow-tooltip="true"
          label="操作事件"
        ></el-table-column>

        <el-table-column
          prop="name"
          min-width="100"
          :show-overflow-tooltip="true"
          label="操作人"
        ></el-table-column>

        <el-table-column
          prop="createTime"
          label="操作时间"
          :show-overflow-tooltip="true"
          min-width="100"
          :formatter="formatTime"
        ></el-table-column>

        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              :disabled="!editPermission"
              type="danger"
              size="small"
              @click="handleDel(scope.$index, scope.row)"
              class="m-l-xs"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--工具条-->
      <el-col :span="24" class="bottom-tool-container">
        <el-button
          type="danger"
          @click="batchRemove"
          :disabled="!this.sels.length > 0 || !editPermission"
        >
          批量删除
        </el-button>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pager.page"
          :page-sizes="[PAGE_SIZE, PAGE_SIZE * 2, PAGE_SIZE * 3, 100]"
          :page-size="pager.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
          class="pull-right"
        ></el-pagination>
      </el-col>
    </div>
  </div>
</template>

<script>
  import { searchSystemlogByKey, delSystemlog } from "../../services/setting";
  import { Notification } from "element-ui";
  import { PAGE_SIZE } from "../../config";

  export default {
    data() {
      return {
        loaded: false,
        editPermission: true,
        PAGE_SIZE,
        total: 1,
        choosedRowIndex: -1, //当前选中的列，主要是在删除和修改的时候用
        systemlogList: [],
        listLoading: false,
        customerDetailVisible: false,
        sels: [], //列表选中列
        fragment: [],
        filters: {
          name: "",
          module: "",
        },
        pager: {
          size: PAGE_SIZE,
          page: 1,
        },
        typeList: [
          { value: "AlertDefine", label: "报警配置" },
          { value: "Customer", label: "客户信息" },
          { value: "DataStreamType", label: "数据类型" },
          { value: "DeviceAlert", label: "设备报警" },
          { value: "Device", label: "设备管理" },
          { value: "DeviceSurplus", label: "设备滤芯" },
          { value: "DeviceType", label: "产品型号" },
          { value: "DeviceUser", label: "设备用户关系" },
          { value: "FirmwareVersion", label: "设备固件" },
          { value: "GroupManage", label: "大屏分组" },
          { value: "KnowledgeBase", label: "知识库" },
          { value: "LogOperate", label: "系统日志" },
          { value: "ProductType", label: "品牌型号" },
          { value: "Role", label: "角色管理" },
          { value: "SurplusRule", label: "滤芯规则" },
          { value: "SystemLog", label: "操作日志" },
        ],
        pickerOptions: {
          disabledDate(time) {},
        },
      };
    },
    methods: {
      formatTime(row) {
        if (row.createTime) {
          return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss");
        }
      },

      normalSearch() {
        this.pager.page = 1;
        this.querySystemlogs();
      },

      /**
       * 单条数据的删除
       */
      handleDel: function(index, row) {
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            delSystemlog(row.id).then((res) => {
              this.listLoading = false;
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.querySystemlogs();
              }
            });
          })
          .catch(() => {});
      },

      /**
       * checkbox勾选的变动的回调
       */
      selsChange: function(sels) {
        this.sels = sels;
      },

      /**
       * 批量删除
       */
      batchRemove() {
        const ids = this.sels.map((item) => item.id).toString();
        this.$confirm("确认删除选中记录吗？", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            delSystemlog(ids).then((res) => {
              this.listLoading = false;
              //NProgress.done();
              if (res.errorCode === 0) {
                Notification.success({
                  title: "成功",
                  message: "批量删除成功",
                });
                this.querySystemlogs();
              }
            });
          })
          .catch(() => {});
      },

      /**
       *  翻页
       */
      handleCurrentChange(page) {
        if (this.loaded) {
          this.pager.page = page;
          window.router.replace({
            path: "systemlog",
            query: { page: page },
          });
          this.querySystemlogs();
        }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.querySystemlogs();
      },

      /**
       * 获取日志信息
       */
      async querySystemlogs() {
        this.listLoading = true;
        const params = Object.assign({}, this.filters);
        if (!params.type) {
          params.type = null;
        }
        if (this.fragment && this.fragment.length === 2) {
          params.from = this.fragment[0];
          params.to = this.fragment[1];
        }
        const responseData = await searchSystemlogByKey(params, this.pager);
        if (responseData.errorCode === 0 && responseData.data) {
          this.systemlogList = responseData.data.list || [];
          this.total = responseData.data.total;
        }
        this.loaded = true;
        this.listLoading = false;
      },
    },
    async created() {
      if (this.$route.query.page) {
        this.pager.page = +this.$route.query.page;
      }
      this.querySystemlogs();
    },
  };
</script>
